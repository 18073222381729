
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        












































































































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.order-by-tel,
[class*='order-by-tel--'] {
  max-height: 85vh;
  padding: $spacing * 1.5 $spacing * 1.5;
  color: $c-promo-card;
  background: $white;
  border-radius: 1rem;

  &.generic-popup {
    display: block;
    overflow: auto;
    max-width: 72rem;
  }

  @include mq(m) {
    width: 72rem;
    padding: $spacing * 2 $spacing * 3;
  }
}

.order-by-tel__title {
  display: flex;
  align-items: center;
  margin-top: $spacing * 0.75;

  span {
    @include mq($until: m) {
      margin-bottom: $spacing;
    }

    @include mq(m) {
      margin-left: $spacing;
    }
  }

  @include mq($until: m) {
    flex-direction: column-reverse;
    align-items: baseline;
  }
}

.order-by-tel__content {
  margin-top: $spacing * 0.5;
}

.order-by-tel__content__value {
  font-weight: 600;
}

.order-by-tel__phone {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: $spacing * 1.5;

  @include mq(m) {
    flex-direction: row;
    align-items: center;
  }
}

.order-by-tel__phone + .order-by-tel__text {
  margin-top: $spacing * 1.5;
  padding-top: $spacing * 1.5;
  border-top: 1px solid $c-gallery;
}

.order-by-tel__phone__link {
  .icon {
    max-height: 2.2rem;
    fill: $white;
  }

  &.is-closed {
    color: $c-gray-lightest;
    pointer-events: none;
  }
}

.order-by-tel__phone__link__label {
  position: relative;
  line-height: 1;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.2rem;
    left: 0;
    display: block;
    width: 100%;
    height: 0.2rem;
    background: $c-blue-dark;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
}

.order-by-tel__phone__icon {
  fill: $c-green-light;

  &.is-busy {
    fill: $c-orange;
  }

  &.is-closed {
    fill: $gray-light;
  }
}

.order-by-tel__links {
  display: flex;
  margin-top: $spacing * 2.5;
  gap: 2rem;
}

.order-by-tel__text {
  ::v-deep h4 {
    margin-top: 0;
  }

  ::v-deep table {
    td:first-child {
      display: flex;
      overflow: hidden;
      width: calc(100vw - 10.6rem);
      white-space: nowrap;

      @include mq(s) {
        width: 30rem;
      }
    }

    td:last-child {
      @extend %fw-semi;

      @include mq(s) {
        padding-left: $spacing * 0.5;
      }
    }

    span {
      padding-right: $spacing * 0.5;

      &::after {
        content: ' ...................................................................................................';
      }
    }

    @include mq($until: s) {
      tr {
        display: flex;
        flex-direction: column;
      }
    }
  }

  ::v-deep .btn--outline {
    color: $c-anthracite-light;
    line-height: 1;

    &:hover {
      color: $white;
    }
  }
}

.order-by-tel__btn {
  @extend %button-nostyle;

  @include mq($until: m) {
    margin-top: $spacing * 0.5;
  }

  @include mq(m) {
    margin-left: $spacing;
  }
}
